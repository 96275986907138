exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accommodation-js": () => import("./../../../src/pages/accommodation.js" /* webpackChunkName: "component---src-pages-accommodation-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-sponsorship-opportunities-js": () => import("./../../../src/pages/sponsorship-opportunities.js" /* webpackChunkName: "component---src-pages-sponsorship-opportunities-js" */),
  "component---src-pages-venue-js": () => import("./../../../src/pages/venue.js" /* webpackChunkName: "component---src-pages-venue-js" */)
}

